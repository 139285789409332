<nav class="navbar fixed-top navbar-dark navbar-expand-lg">
    <div class="container-sm">
        <button (click)="toggleMenu()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
            <img src="/assets/images/button-menu.png" alt="Button Menu" class="button-menu">
        </button>
        <a class="navbar-brand d-lg-none" href="{{ 'PRESSKIT_URL' | translate}}" target="_blank">
            <div class="nav-link nav-press-kit">
                <div class="button-text">
                    {{ 'NAVBAR.text-4' | translate}}
                </div>
            </div>

        </a>


        <div class="collapse navbar-collapse pb-5" id="navbarToggle">
            <ul class="navbar-nav d-flex flex-column flex-lg-row align-items-center mr-auto mt-2 mt-lg-0">
                <li class="nav-item active">
                    <a (click)="goToSection('features-section')" class="nav-link nav-features">
                        <div class="button-text">
                            {{ 'NAVBAR.text-1' | translate}}
                        </div>
                    </a>
                </li>
                <li class="nav-item active px-lg-3">
                    <a (click)="goToSection('how-to-section')" class="nav-link nav-how-to my-4 my-lg-0">
                        <div class="button-text">
                            {{ 'NAVBAR.text-2' | translate}} </div>
                    </a>
                </li>
                <li class="nav-item active">
                    <a (click)="goToSection('contact-section')" class="nav-link nav-contact">
                        <div class="button-text">
                            {{ 'NAVBAR.text-3' | translate}}
                        </div>
                    </a>
                </li>
                <li class="nav-item active d-inline d-lg-none pt-5">
                    <a (click)="goTo('/terms')" class="nav-link nav-terms" href="#">
                            {{ 'TERMS.terms' | translate}}
                    </a>
                </li>
                <li class="nav-item active d-inline d-lg-none">
                    <a (click)="goTo('/privacy')" class="nav-link nav-terms" href="#">
                            {{ 'TERMS.privacy' | translate}}
                    </a>
                </li>
                <li class="d-inline d-md-none">
                    <div class="language-menu button-text white pr-4 d-flex flex-row justify-content-center align-items-center pointer">
                        <div (click)="changeLan('es')" [ngClass]="{'current-language' : currentLanguage === 'es'}" class="language-selection">
                            <img class="img-fluid" src="assets/images/es.png" alt="ES">
                        </div>
                        <div (click)="changeLan('en')" [ngClass]="{'current-language' : currentLanguage === 'en'}" class="language-selection  mx-2">
                            <img class="img-fluid" src="assets/images/en.png" alt="EN">
                        </div>
                        <div (click)="changeLan('pt')" [ngClass]="{'current-language' : currentLanguage === 'pt'}" class="language-selection">
                            <img class="img-fluid" src="assets/images/pt.png" alt="PT">
                        </div>
                    </div>
                </li>
            </ul>
            <div class="my-2 d-none d-lg-inline my-lg-0">
                <div class="d-flex flex-row">
                    <div class="language-menu button-text white pr-4 d-flex flex-row justify-content-center align-items-center pointer">
                        <div (click)="changeLan('es')" [ngClass]="{'current-language' : currentLanguage === 'es'}" class="language-selection">
                            <img class="img-fluid" src="assets/images/es.png" alt="ES">
                        </div>
                        <div (click)="changeLan('en')" [ngClass]="{'current-language' : currentLanguage === 'en'}" class="language-selection  mx-2">
                            <img class="img-fluid" src="assets/images/en.png" alt="EN">
                        </div>
                        <div (click)="changeLan('pt')" [ngClass]="{'current-language' : currentLanguage === 'pt'}" class="language-selection">
                            <img class="img-fluid" src="assets/images/pt.png" alt="PT">
                        </div>
                    </div>
                    <a class="nav-link nav-press-kit" href="{{ 'PRESSKIT_URL' | translate}}" target="_blank">
                        <div class="button-text">
                            {{ 'NAVBAR.text-4' | translate}}
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </div>

</nav>