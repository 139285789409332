<div class="container-fluid position-relative d-flex justify-content-center">
    <div (click)="goBack()" class="back-button"></div>
    <div class="container m-3 p-5">
        <div id="title" class="col-12 px-5 text-center">
            <h1> TÉRMINOS Y CONDICIONES

            </h1>
        </div>
        <div class="col-12 px-5">
            <p class="p3">Este contrato describe los Términos y Condiciones generales (en adelante “TÉRMINOS Y CONDICIONES”) aplicables al uso de los contenidos, productos y servicios ofrecidos a través del programa de cómputo aplicación EL CHAVO KART (en adelante
                “APP”), del cual es titular KOKONUT STUDIO, S. DE R.L. DE C.V., (en adelante “TITULAR”), el cual es el desarrollador del proyecto y propietario de la licencia de sus contenidos, asimismo se indica que GAMES FOR PEOPLE, S.A.P.I DE C.V.,
                es la propietaria del proyecto.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3">Cualquier persona que desee acceder o hacer uso del sitio o los servicios que en él se ofrecen, podrán hacerlo sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como a las políticas y principios incorporados al presente documento. En
                todo caso, cualquier persona que no acepte los presentes TÉRMINOS Y CONDICIONES, deberá abstenerse de utilizar la APP<span class="Apple-converted-space">  </span>y/o adquirir los servicios que en ella de ofrecen.</p>
            <p class="p2"><br></p>
            <p class="p3">Para el caso de que el USUARIO continúe en el uso de la APP, dicha acción se considera como su absoluta aceptación a los TÉRMINOS Y CONDICIONES aquí establecidos.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>DEL OBJETO.</b></p>
            <p class="p5"><b></b><br></p>
            <p class="p3">El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la utilización de la APP, entendiendo por este cualquier tipo de contenido, producto o servicio que se encuentre a disposición del público en general dentro del dominio
                EL CHAVO KART.</p>
            <p class="p2"><br></p>
            <p class="p3">EL TITULAR se reserva la facultad de modificar en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, los servicios y la configuración que pudiera estar con tenida en la APP; en este sentido, el USUARIO
                reconoce y acepta que el TITULAR en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman la APP o el acceso a los mismos.</p>
            <p class="p2"><br></p>
            <p class="p3">El acceso a la APP por parte del USUARIO tiene carácter<span class="Apple-converted-space">  </span>libre y<span class="Apple-converted-space">  </span>por regla general es gratuito sin que el USUARIO tenga que proporcionar una contraprestación
                para poder disfrutar de ello, salvo en lo referente al costo de la conexión a internet suministrada por el proveedor de este tipo de servicios que hubiere contratado el mismo USUARIO.</p>
            <p class="p2"><br></p>
            <p class="p3">El acceso a parte de los contenidos y servicios de la APP podrá realizarse previa suscripción o registro previo del USUARIO.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>DEL USUARIO.</b></p>
            <p class="p5"><b></b><br></p>
            <p class="p3">El acceso o utilización de la APP, así como de los recursos habilitados para interactuar entre el USUARIO y el TITULAR tales como medios para realizar publicaciones o comentarios, confiere la condición de USUARIO y de la APP, por lo que quedará
                sujeto a los presentes TÉRMINOS Y CONDICIONES, así como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede a la APP. Dada
                la relevancia de lo anterior, se recomienda al USUARIO revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES.</p>
            <p class="p2"><br></p>
            <p class="p3">Es responsabilidad del USUARIO utilizar la APP de acuerdo a la forma en la que fue diseñada; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios
                proporcionados a través de la APP. Además, el USUARIO se compromete a utilizar la información, contenidos o servicios ofrecidos a través de la APP de manera lícita, sin contravenir lo dispuesto en los presentes TÉRMINOS Y CONDICIONES,
                la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de terceros o perjudique de algún modo el funcionamiento de la APP.</p>
            <p class="p2"><br></p>
            <p class="p3">Asimismo, el usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en la APP, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o
                servicio ofrecido por la propia APP. En todo caso, el USUARIO notificará de forma inmediata al TITULAR acerca de cualquier hecho que permita suponer el uso indebido de información registrada en dichos formularios, tales como, robo, extravío,
                o acceso no autorizado a cuentas y/o contraseñas con el fin de proceder a su inmediata cancelación.</p>
            <p class="p2"><br></p>
            <p class="p3">EL TITULAR se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, atenten contra los derechos de tercero o el orden público, o bien,
                que a su juicio no resulten adecuados para su publicación.</p>
            <p class="p2"><br></p>
            <p class="p3">En cualquier caso, EL TITULAR no será responsable de las opiniones vertidas por los USUARIOS<span class="Apple-converted-space">  </span>a través de comentarios o publicaciones que estos realicen.</p>
            <p class="p2"><br></p>
            <p class="p3">El sólo acceso a la APP no supone el establecimiento de ningún tipo de relación entre el TITULAR y el USUARIO.</p>
            <p class="p2"><br></p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>DEL ACCESO A LA APP.</b></p>
            <p class="p5"><b></b><br></p>
            <p class="p3">El TITULAR no garantiza de ningún modo la continuidad y disponibilidad de los contenidos, productos o servicios ofrecidos a través de la APP, no obstante, el TITULAR llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan
                mantener el buen funcionamiento de la APP, sin que esto suponga alguna responsabilidad de parte de EL TITULAR.</p>
            <p class="p2"><br></p>
            <p class="p3">De igual forma EL TITULAR no será responsable ni garantiza que el contenido o software al que pueda accederse a través de la APP, se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware
                en el equipo a través del cual el USUARIO accede a la APP.</p>
            <p class="p2"><br></p>
            <p class="p3">El TITULAR tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado de la APP. En ningún caso EL TITULAR será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o
                utilización de la APP.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>FUNCIONAMIENTO</b></p>
            <p class="p5"><b></b><br></p>
            <p class="p3">EL CHAVO KART se trata de un juego de carreras, mediante el cual el USUARIO debe participar constantemente en las mismas, para así poder avanzar en los diferentes niveles y estar en posibilidades de desbloquear distintas carreras.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3">Conforme avanza el USUARIO, podrá ir desbloqueando nuevas pistas, personajes, karts y llantas. El objetivo es poder acceder a la mayor cantidad de pistas posibles.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>ALTA/BAJA</b></p>
            <p class="p2"><b></b><br></p>
            <p class="p3">El USUARIO deberá crear su cuenta antes de iniciar el juego de EL CHAVO KART, así como aceptar estos TÉRMINOS Y CONDICIONES.</p>
            <p class="p2"><br></p>
            <p class="p3">En caso de que el USUARIO decida dar de baja la suscripción de EL CHAVO KART, deberá realizarlo dentro de la misma APP.</p>
            <p class="p2"><br></p>
            <p class="p3">La ayuda/soporte estará disponible a través de la cuenta de Facebook de EL CHAVO KART.</p>
            <p class="p5"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>OBJETOS VIRTUALES</b></p>
            <p class="p3">Las carreras de EL CHAVO KART pueden permitir al USUARIO “ganar gemas”, las cuales<span class="Apple-converted-space">  </span>carecen de valor monetario en el mundo real, solo generan ciertos beneficios para el juego. Los objetos virtuales
                no se pueden canjear por dinero, bienes u otros objetos reales de valor monetario.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3">En caso de que el USUARIO decida cancelar la suscripción, este perderá automáticamente las “gemas” ganadas durante el tiempo que estuvo activa su suscripción.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>PLUBLICIDAD</b></p>
            <p class="p5"><b></b><br></p>
            <p class="p6">Al utilizar nuestra APP, tal vez se ofrezca contenido publicitario a través del proveedor Unity Ads (<span class="s1">https://unity3d.com/unity/features/ads</span>), por lo que <span class="s1">EL TITULAR</span> no es responsable de los productos
                o servicios de terceros que aparezcan en la misma y que el mismo USUARIO desee adquirir. Asimismo se hace de su conocimiento que <span class="s1">EL TITULAR</span> no proporcionará ningún dato personal para información ajena de la APP.</p>
            <p class="p7"><br></p>
            <p class="p7"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</b></p>
            <p class="p5"><b></b><br></p>
            <p class="p3">De conformidad con lo establecido en la Ley Federal de Datos Personales en Posesión de Particulares el TITULAR se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados
                de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.</p>
            <p class="p2"><br></p>
            <p class="p3">Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través de la APP, será tratado de conformidad con los principios de
                licitud, calidad, finalidad, lealtad y responsabilidad. Todo tratamiento de datos personales quedará sujeto al consentimiento de su titular. En todo caso, la utilización de datos financieros o patrimoniales, requerirán de autorización
                expresa de sus titulares, no obstante, esta podrá darse a través de la propia APP utilizando los mecanismos habilitados para tal efecto y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en el
                caso de datos personales sensibles, considerando por estos aquellos que debido a una utilización indebida puedan dar origen a discriminación o su divulgación conlleve un riesgo para el titular.</p>
            <p class="p2"><br></p>
            <p class="p3">En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.</p>
            <p class="p2"><br></p>
            <p class="p3">El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el <b>Aviso de Privacidad</b> el cual se encontrará disponible en la misma APP.</p>
            <p class="p2"><br></p>
            <p class="p3">La APP podrá incluir hipervínculos o enlaces que permitan acceder a páginas web de terceros distintos de EL TITULAR. Los titulares de dichos sitios web dispondrán de sus propias políticas de privacidad y protección de datos,<span class="Apple-converted-space">  </span>por
                lo cual EL TITULAR no asume ningún tipo de responsabilidad por los datos que son facilitados por el USUARIO<span class="Apple-converted-space">  </span>a través de cualquier sitio web distinto a éste.</p>
            <p class="p2"><br></p>
            <p class="p3">EL TITULAR se reserva el derecho a modificar su Política de Privacidad de acuerdo a sus necesidades o derivado de algún cambio en la legislación. El acceso o utilización de la APP después de dichos cambios, implicará la aceptación de estos
                cambios.</p>
            <p class="p2"><br></p>
            <p class="p3">Por otra parte, el acceso a la APP puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el USUARIO. Las cookies facilitan la navegación, la hacen más amigable
                y no dañan el dispositivo de navegación, para ello, pueden recabar la información para ingresar a la APP, almacenar las preferencias del USUARIO, así como la interacción que este tenga con la APP, como por ejemplo: la fecha y hora en la
                que se accede a la APP, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección<span class="Apple-converted-space">  </span>IP de la cual accede el usuario, la
                frecuencia de visitas, etc.</p>
            <p class="p2"><br></p>
            <p class="p3">Este tipo de información será utilizada para mejorar la APP, detectar errores y posibles necesidades que el USUARIO pueda tener, lo anterior a efecto de ofrecer a los USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información
                que se recopile será anónima y no se identificará a usuarios individuales.</p>
            <p class="p2"><br></p>
            <p class="p3">En caso de que el USUARIO no desee que se recopile este tipo de información deberá deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizar estas acciones pueden diferir
                de un navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del navegador . En el supuesto de que rechace el uso de cookies (total o parcialmente) el USUARIO podrá continuar haciendo uso
                de la APP, aunque podrían quedar deshabilitadas algunas de las funciones del mismo.</p>
            <p class="p2"><br></p>
            <p class="p3">Es posible que en un futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES, con el objetivo de estar adecuadamente
                informado sobre cómo y para qué utilizamos las cookies que se general al ingresar o hacer uso de la APP.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</b></p>
            <p class="p2"><b></b><br></p>
            <p class="p3">EL TITULAR, es titular de todos los derechos de propiedad intelectual del código fuente<span class="Apple-converted-space">  </span>de la APP, entendiendo por este el código fuente que hace posible su funcionamiento y a su vez es licenciatario
                de l las imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad
                intelectual e industrial, así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución o difusión de los contenidos de la APP con fines comerciales en cualquier soporte
                y por cualquier medio sin la autorización de EL TITULAR.</p>
            <p class="p2"><br></p>
            <p class="p3">El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial del TITULAR. No obstante, además de poder visualizar los elementos de la APP podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente
                para su uso estrictamente personal.</p>
            <p class="p2"><br></p>
            <p class="p3">Por otro lado, el USUARIO, se abstendrá de suprimir, alterar o manipular cualquier elemento, archivo o contenido de la APP y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o base de datos que se encuentren
                protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.</p>
            <p class="p2"><br></p>
            <p class="p3">En caso de que el USUARIO o algún tercero consideren que cualquiera de los contenidos de la APP suponga una violación de los derechos de protección de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a EL CHAVO KART
                a través de los datos de contacto disponibles en la propia APP.</p>
            <p class="p2"><br></p>
            <p class="p4"><b><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</b></p>
            <p class="p2"><b></b><br></p>
            <p class="p3">EL TITULAR se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida de la APP, sus contenidos, productos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y CONDICIONES.</p>
            <p class="p2"><br></p>
            <p class="p3">La relación entre el USUARIO y EL TITULAR se regirá por la legislación vigente en nuestro país. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de los presentes TÉRMINOS Y CONDICIONES, las partes se someterán
                a la jurisdicción ordinaria de las leyes y competencia de os tribunales de la Ciudad de México.</p>
        </div>
    </div>


</div>