import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    const element = document.getElementById('title');    
    element.scrollIntoView();
  }

  goBack() {
    this.router.navigate(['/home']);
  }

}
