<div class="container-fluid position-relative d-flex justify-content-center">
    <div (click)="goBack()" class="back-button"></div>
    <div class="container m-3 p-5">
        <div id="title" class="col-12 px-5 text-center">
            <h1> AVISO DE PRIVACIDAD
            </h1>
        </div>
        <div class="col-12 px-5">
            <p class="p2"><b></b><br></p>
            <p class="p3"><b>KOKONUT STUDIO S. DE R.L. DE C.V.,</b> con domicilio en <span class="s1">2ª Privada de Concepción Beistegui, número 6, piso 2, colonia del Valle, alcaldía Benito Juárez, C.P. 03100,</span> Ciudad de México, número telefónico <span class="s2">55 6235 061,</span>                de conformidad con las disposiciones indicadas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante la Ley), su reglamento y sus lineamientos, hacen de su conocimiento que:</p>
            <p class="p2"><br></p>
            <p class="p3"><b>KOKONUT STUDIO S. DE R.L. DE C.V.,</b> protege y salvaguarda sus datos personales para evitar el daño, pérdida, destrucción, robo, extravío, alteración, así como el tratamiento no autorizado de los mismos.</p>
            <p class="p2"><br></p>
            <p class="p3">Este Aviso de Privacidad tiene como objeto informar a los visitantes de la aplicación “EL CHAVO KART”, clientes pasados o actuales (en adelante Usuarios) los fines de los Datos Personales que se comparten, con quien se comparten, así como
                los medios y el procedimiento que ponemos a su disposición para ejercer sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de datos pesonales o para revocar el consentimiento que previamente se ha otorgado.
                <span
                    class="Apple-converted-space"> </span>
            </p>
            <p class="p2"><br></p>
            <p class="p3">Se da por entendido que, si se proporcionan los datos personales solcitados, está de acuerdo con los términos de este Aviso y las finalidades del tratamiento de sus datos personales.</p>
            <p class="p2"><br></p>
            <p class="p3">Los términos con mayúscula incluidos en este Aviso se entienden referidos a las definiciones contenidas en la Ley, el Reglamento o las que se establezcan en el presente Aviso.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>I. RESPONSABLE</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">La responsabilidad para el tratamiento de sus datos estará a cargo de Benjamín Morales Venegas; con domicilio en <span class="s1">2ª Privada de Concepción Beistegui, número 6, piso 2, colonia del Valle, alcaldía Benito Juárez, C.P. 03100, Ciudad de México</span>,
                el cual puede ser contactado a través del correo electrónico: <span class="s4">benjamin@kokonutstudio.com.</span></p>
            <p class="p4"><br></p>
            <p class="p3"><span class="s3"><b>II. FINALIDADES</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">La obtención, uso, divulgación o almacenamiento, así como el acceso, manejo, aprovechamiento, transferencia o disposición que se llevan a cabo de los datos personales de los Usuarios, tiene como finalidades las siguientes:</p>
            <p class="p2"><br></p>
            <p class="p5"><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>Dar cumplimiento a la legislación aplicable</p>
            <p class="p5"><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>Dar cumplimiento a las obligaciones contractuales contraídas con nuestros clientes para los servicios de recepción y procesamientos de pagos, generación de los comprobantes
                fiscales por nuestros servicios, alta de cuenta en nuestra base de datos, notificaciones relacionadas con nuestro servicio.</p>
            <p class="p2"><br></p>
            <p class="p3">En caso de que se celebre un acto relacionado con algún servicio ofrecido por nosotros, sus datos personales serán utilizados para el cumplimiento de las obligaciones derivadas de esa relación jurídica. En caso de que no se concrete ningún
                acto, los datos personales que nos haya proporcionado serán bloqueados y posteriormente eliminados.</p>
            <p class="p2"><br></p>
            <p class="p3">La información que ha sido bloqueada puede conservarse por el plazo necesario para cumplir con disposiciones legales, como son fiscales o de naturaleza mercantil y posteriormente será suprimida.<span class="Apple-converted-space"> </span></p>
            <p class="p3"><span class="s3"><b>III. DATOS OBTENIDOS</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Los datos personales son recabados de forma directa cuando se proporcionan por medio del registro en la aplicación “EL CHAVO KART”<span class="s5">;</span> los datos que obtenemos por este medio pueden ser, entre otros:</p>
            <p class="p2"><br></p>
            <p class="p3">NOMBRE y CORREO ELECTRÓNICO.</p>
            <p class="p2"><br></p>
            <p class="p3"><b>KOKONUT STUDIO S. DE R.L. DE C.V.</b> no almacena ningún tipo de dato, estos se almacenan a través de Game Sparks (https://www.gamesparks.com/), por lo que ellos son los únicos responsables de la seguridad y resguardo de los mismos. Cabe
                destacar que Game Sparks relacionan su registro a través de las cuentas de Facebook Inc., Apple Inc., y de Google LLC (depende de la plataforma), siendo ellos de igual forma responsables de solicitar datos dentro de la APP.</p>
            <p class="p2"><br></p>
            <p class="p3">El almacenamiento de los analíticos se llevará a cabo a través del proveedor Unity Analytics (https://unity3d.com/unity/features/analytics) quien será el responsable de recabar la información así como el uso de la misma, esto bajo la regulación
                de la legislación de los Estados Unidos de América.</p>
            <p class="p2"><br></p>
            <p class="p3">Cabe destacar, que <b>KOKONUT STUDIO S. DE R.L. DE C.V.</b>, únicamente hace uso de la información que comparte el proveedor mediante la plataforma de los usuarios para dar servicio al proyecto.</p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>IV. TRANSFERENCIAS DE DATOS</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Con fundamento en los artículos 36 y 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, los datos personales pueden ser transferidos lícitamente para las finalidades establecidas en el presente Aviso de
                Privacidad, en el supuesto que por petición o mandato de autoridad competente se solicite a la aplicación “EL CHAVO KART”, esta se compromete a entregar únicamente la información que le haya sido requerida, haciendo su mejor esfuerzo para
                que en caso de que la autoridad no haya delimitado la información solicitada, busque que se delimite con el objeto de afectar lo menos posible lo relativo a la divulgación de los datos personales de los Usuarios.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3">No obstante lo anterior, y con el único fin de prevenir la realización de conductas que pudieran ser constitutivas de algún delito de los previstos por la legislación penal vigente, la aplicación “EL CHAVO KART” podrá compartir con terceros
                la información que estime pertinente a fin de conducir la o las investigaciones que procedan.</p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>V. PROTECCIÓN DE DATOS</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3"><b>KOKONUT STUDIO S. DE R.L. DE C.V.,</b> a través de la aplicación “EL CHAVO KART” ha adoptado los niveles de seguridad de protección de los datos personales legalmente requeridos y procura implementar aquellos otros medios y medidas técnicas
                adicionales a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados.</p>
            <p class="p2"><br></p>
            <p class="p3">Las medidas de seguridad administrativas, técnicas y físicas implementadas por la aplicación para proteger sus datos personales, son las mismas que exigimos sean cumplidas por los proveedores de servicios que contactamos.</p>
            <p class="p2"><br></p>
            <p class="p3">Toda la información y datos personales proporcionados al utilizar nuestro servicio, constituirán una base de datos propiedad de la aplicación “EL CHAVO KART”. La información se almacena con fines de protección, para evitar pérdida, uso indebido
                o alteración.</p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>VI. DERECHOS ARCO</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Puede ejercer los derechos de acceso, rectificación, cancelación u oposición, así como revocar su consentimiento para el tratamiento de sus Datos (derechos ARCO) presentando una solicitud en:<span class="s1"> 2ª Privada de Concepción Beistegui, número 6, piso 2, colonia del Valle, alcaldía Benito Juárez, C.P. 03100,</span>                Ciudad de México o bien por medios electrónicos según se establece a continuación: <span class="s4">benjamin@kokonutstudio.com.</span></p>
            <p class="p4"><br></p>
            <p class="p3">Dicha solicitud deberá contener por lo menos: (a) nombre completo del solicitante; (b) indicar si la solicitud se presenta personalmente o a través de su representante, (c) domicilio, (d) teléfono;(e) indicar si acepta que las notificaciones
                que realicemos en relación con su solicitud surtan efectos a través del correo electrónico que nos proporcione; (f) la descripción clara y precisa de los Datos Personales respecto de los que solicita ejercer alguno de los derechos ARCO,
                (g) en su caso, su manifestación expresa para revocar el consentimiento al tratamiento de sus Datos; (h) cualquier otro elemento que facilite la localización de sus Datos (fecha en que se nos proporcionaron los Datos, número de identificación
                del acto celebrado, persona que te atendió, áreas involucradas) e (i) los documentos que acrediten su identidad y/o personalidad con poder notarial.</p>
            <p class="p2"><br></p>
            <p class="p3">De conformidad con la Ley, para dar trámite a su solicitud previamente deberá acreditar su identidad o personalidad mediante asistencia personal, presentando original y copia de su identificación oficial y/o poder notarial.</p>
            <p class="p2"><br></p>
            <p class="p3">Cuando se presenten solicitudes de Acceso y Rectificación y si reside fuera de la Ciudad de Mèxico podrá enviar la solicitud vía correo electrónico, adjuntando copia digitalizada de su identificación oficial y/o poder notarial. Asimismo, el
                titular, garantiza que los Datos proporcionados son fidedignos, exactos y completos, por lo cual se hace responsable por cualquier daño y perjuicio por falsedad o suplantación de identidad.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3">Tratándose de las solicitudes de Rectificación deberá acompañar los documentos que acrediten la solicitud y los Datos en específico que desee modificar, dichos documentos deben presentarse en original y copia para su cotejo.</p>
            <p class="p2"><br></p>
            <p class="p3">El resto del procedimiento será tramitado conforme a los plazos y etapas establecidos en la Ley.</p>
            <p class="p2"><br></p>
            <p class="p3">Si ya no desea recibir información de nuestros servicios o desea optar por limitar el uso, transferencia o divulgación de sus Datos podrá enviarnos su solicitud a la dirección de correo electrónico <span class="s4">benjamin@kokonutstudio.com</span>
                <span
                    class="s6">.</span>
            </p>
            <p class="p6"><br></p>
            <p class="p3"><span class="s3"><b>VII. PRIVACIDAD DE LOS MENORES</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">El servicio está limitado a menores de 13 años de edad,<span class="Apple-converted-space">  </span>por tal motivo no establecemos ni conservamos registros de Datos de cualquier menor de edad del que tengamos conocimiento. No obstante, si
                descubrimos que hemos recibido Datos o información personal de una persona que indica que él o ella es menor de edad, o de cualquier otra persona que tengamos motivos para creer que es menor de edad, cancelaremos dichos Datos en términos
                de lo establecido por la Ley.</p>
            <p class="p3">La protección a los menores de 13 años de edad, es con el objetivo de que ningún producto digital almacene datos, analíticos, cifras, estadísticas o información relacionada con ellos, esto con el objetivo principal de que no se muestren anuncios
                para ellos. Para estar en condiciones de lograr este objetivo, el juego preguntará en la primera ocasión que de abra el juego que se indique la edad bajo el siguiente esquema:</p>
            <p class="p2"><br></p>
            <p class="p5"><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>Si se indica que es menor de 13 años, el usuario jugará como invitado, no se le solicitará registrarse y no se almacenará ningún tipo de dato con Unity Analytics, así
                como tampoco<span class="Apple-converted-space">  </span>se mostrará ninguna publicidad.</p>
            <p class="p5"><span class="Apple-tab-span">	</span>•<span class="Apple-tab-span">	</span>Si se indica que tiene 13 años o más, el usuario tendrá que registrarse, se almacenarán sus analíticas y se le mostrará publicidad.</p>
            <p class="p2"><br></p>
            <p class="p3">En caso de que el menor de edad dolosamente se ostente como mayor de edad, éste quedará sujeto a las responsabilidades de cualquier naturaleza que correspondan. Por lo que solicitamos la supervisión directa a los menores mientras están en
                línea o cuando realizan llamadas telefónicas.</p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>VIII. CONSENTIMIENTO</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Cuando sea aplicable, si no manifiesta su oposición para que sus Datos sean tratados en los términos señalados en el presente Aviso, se entenderá que ha otorgado su consentimiento para ello.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>IX. COOKIES Y WEB BEACONS</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p7">Le informamos que en nuestra aplicación <span class="s1">“EL CHAVO KART” </span>usamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de la aplicación, así como para brindarle
                un mejor servicio y experiencia al navegar en nuestra aplicación. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines: Para verificar y confirmar la identidad del usuario.</p>
            <p class="p8"><br></p>
            <p class="p7">Los datos personales que obtenemos de esta tecnologías de rastreo son los siguientes: identificadores, nombre de usuario y contraseñas de una sesión, fecha y hora del inicio y final de una sesión de un usuario.</p>
            <p class="p8"><br></p>
            <p class="p3"><span class="s3"><b>X. QUEJAS Y DENUNCIAS</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas y presume que en el tratamiento de sus datos personales existe alguna violación
                a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información
                y Protección de Datos Personales (INAI), para mayor información visite la página www.inai.org.mx.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>XI. ACTUALIZACIÓN DEL AVISO</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Nuestra empresa se reserva el derecho de modificar o complementar este Aviso de Privacidad en cualquier momento. En caso de existir alguna modificación al presente Aviso, ésta se hará de su conocimiento por medio de correo electrónico, directamente
                en la aplicación o podrá consultarlo en nuestro domicilio.<span class="Apple-converted-space"> </span></p>
            <p class="p2"><br></p>
            <p class="p3"><span class="s3"><b>XII. CONTÁCTENOS</b></span></p>
            <p class="p2"><span class="s3"><b></b></span><br></p>
            <p class="p3">Si tiene preguntas sobre este Aviso, el manejo de sus datos personales o información de nuestros productos, servicios, por favor contacte a nuestro encargado de protección de datos personales al correo electrónico: <span class="s4">benjamin@kokonutstudio.com.</span></p>
            <p class="p4"><br></p>
        </div>
    </div>


</div>