import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ChavoKartLanding';
  constructor(
    public translate: TranslateService,
    private metaTagService: Meta) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/es/) ? browserLang : 'en');
    localStorage.setItem('language' , browserLang);
  }

  ngOnInit() {
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Jump in your favorite kart! Ready for a blast from the past in a whole new way?' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Kokonut Studio' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-05-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }
}
