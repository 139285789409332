<div class="form-container col-12 col-md-8 offset-0 offset-md-2">
    <div *ngIf="formSent" class="blue thanks-message py-5 text-center">
        Thank you! We'll get you back in no time
    </div>
    <form [formGroup]="form" (ngSubmit)="form.valid && sendContact()" autocomplete="off">
        <div class="pl-0 pl-md-1 position-relative">
            <div *ngIf="this.form.controls.nombre.valid" class="position-absolute icon-check icon-for-nombre">
                <img src="/assets/images/icon-check.png" alt="Icon Check" class="img-fluid">
            </div>
            <input placeholder="Your name" formControlName="nombre">
        </div>
        <div class="my-5 pr-0 pr-md-1 position-relative">
            <div *ngIf="this.form.controls.email.valid" class="position-absolute icon-check icon-for-email">
                <img src="/assets/images/icon-check.png" alt="Icon Check" class="img-fluid">
            </div>
            <input placeholder="Your email" formControlName="email">
        </div>
        <div class=" position-relative">
            <div *ngIf="this.form.controls.message.valid" class="position-absolute icon-check icon-for-message">
                <img src="/assets/images/icon-check.png" alt="Icon Check" class="img-fluid">
            </div>
            <textarea formControlName="message" placeholder="Your message"></textarea>
        </div>
        <div class="text-center pt-5">
            <button class="contact-button">
                <div class="module">
                    Boton
                </div>
            </button>
        </div>
    </form>
</div>