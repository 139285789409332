import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, ExtraOptions } from '@angular/router';
import { HomeComponent } from '../app/components/home/home.component';
import { PrivacyComponent } from '../app/components/privacy/privacy.component';
import { TermsComponent } from '../app/components/terms/terms.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always',
    useHash: false
  }),
  RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
