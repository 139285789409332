import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidateEmptySpaces } from '../validators/additional_validators';
import * as ValidationMessages from '../validators/validation_messages';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { ContactService } from '../services/contact.service';
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  formSent = false;
  mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
  constructor(
    public dialog: MatDialog,
    private ngxService: NgxUiLoaderService,    
    private contactService: ContactService,  
    private alertService: AlertService,    
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        ValidateEmptySpaces,
        Validators.pattern(this.mailRegex)
      ]),
      nombre: new FormControl('', [
        Validators.required,
        ValidateEmptySpaces
      ]),
      message: new FormControl('', [
        Validators.required,
        ValidateEmptySpaces,
      ]),
    });

    this.form.valueChanges.subscribe(
      data => { });
  }

  sendContact() {
    console.log(this.form.value);
   
  }

}
