<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem class="slide d-flex align-items-center justify-content-center flex-column flex-lg-row ">
        <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <div class="image-container">
                <img class="img-fluid" src="/assets/images/slides/slide-1.png" alt="Image Slide" width="100%">
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="text-container d-flex flex-column">
                <div class="slide-title-container text-left">
                    <h2 class="slide-title" innerHtml="{{ 'HOME.text-5' | translate}}"></h2>
                </div>
                <div class="slide-paragraph-container text-left">
                    <p class="slide-paragraph pt-3" innerHtml=" {{ 'HOME.text-6' | translate}}"></p>
                </div>
            </div>
        </div>

    </div>
    <div ngxSlickItem class="slide d-flex align-items-center justify-content-center flex-column flex-lg-row ">
        <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <div class="image-container">
                <img class="img-fluid" src="/assets/images/slides/slide-2.png" alt="Image Slide" width="100%">
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="text-container d-flex flex-column">
                <div class="slide-title-container text-left">
                    <h2 class="slide-title" innerHtml="{{ 'HOME.text-7' | translate}}"></h2>
                </div>
                <div class="slide-paragraph-container text-left">
                    <p class="slide-paragraph pt-3" innerHtml=" {{ 'HOME.text-8' | translate}}"></p>
                </div>
            </div>
        </div>

    </div>
    <div ngxSlickItem class="slide d-flex align-items-center justify-content-center flex-column flex-lg-row ">
        <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <div class="image-container">
                <img class="img-fluid" src="/assets/images/slides/slide-3.png" alt="Image Slide" width="100%">
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="text-container d-flex flex-column">
                <div class="slide-title-container text-left">
                    <h2 class="slide-title" innerHtml="{{ 'HOME.text-14' | translate}}"></h2>
                </div>
                <div class="slide-paragraph-container text-left">
                    <p class="slide-paragraph pt-3" innerHtml=" {{ 'HOME.text-15' | translate}}"></p>
                </div>
            </div>
        </div>

    </div>
    <div ngxSlickItem class="slide d-flex align-items-center justify-content-center flex-column flex-lg-row ">
        <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <div class="image-container">
                <img class="img-fluid" src="/assets/images/slides/slide-4.png" alt="Image Slide" width="100%">
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="text-container d-flex flex-column">
                <div class="slide-title-container text-left">
                    <h2 class="slide-title" innerHtml="{{ 'HOME.text-16' | translate}}"></h2>
                </div>
                <div class="slide-paragraph-container text-left">
                    <p class="slide-paragraph pt-3" innerHtml=" {{ 'HOME.text-17' | translate}}"></p>
                </div>
            </div>
        </div>

    </div>
    <div ngxSlickItem class="slide d-flex align-items-center justify-content-center flex-column flex-lg-row ">
        <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <div class="image-container">
                <img class="img-fluid" src="/assets/images/slides/slide-5.png" alt="Image Slide" width="100%">
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="text-container d-flex flex-column">
                <div class="slide-title-container text-left">
                    <h2 class="slide-title" innerHtml="{{ 'HOME.text-9' | translate}}"></h2>
                </div>
                <div class="slide-paragraph-container text-left">
                    <p class="slide-paragraph pt-3" innerHtml=" {{ 'HOME.text-10' | translate}}"></p>
                </div>
            </div>
        </div>

    </div>
</ngx-slick-carousel>