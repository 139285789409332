import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Output() selectedLan = new EventEmitter();
  isMenuOpen = false;
  toggleMenuItem;
  pressButton;
  currentLanguage = localStorage.getItem('language');
  constructor(
    public translate: TranslateService,
    private router: Router  ,
  ) {
    console.log(this.currentLanguage);
  }

  ngOnInit(): void {
    this.toggleMenuItem = $('.navbar');
    this.pressButton = $('.navbar-brand');
    console.log(this.toggleMenuItem);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if (this.isMenuOpen) {
      this.toggleMenuItem.addClass('bg-dark');
      this.pressButton.addClass('d-none');
    } else {
      setTimeout(() => {
        this.toggleMenuItem.removeClass('bg-dark');
        this.pressButton.removeClass('d-none');
      }, 400);
    }
  }

  goToSection(sectionId) {
    console.log(sectionId);
    const element = document.getElementById(sectionId);
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  goTo(route) {
    this.router.navigate([route]);
  }

  changeLan(newLan) {
    console.log(newLan);
    this.selectedLan.emit(newLan);
    localStorage.setItem('language', newLan);
    this.currentLanguage = newLan;
    // this.translate.use(newLan);
  }

}
