import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  currentLanguage;
  metaTags = {
    en: {
      title: 'El Chavo Kart',
      description: 'Jump in your favorite kart! Ready for a blast from the past in a whole new way?'
    },
    es: {
      title: 'El Chavo Kart',
      description: '¡EL PILOTO FAVORITO DE LA VECINDAD ESTÁ DE REGRESO! ¿Estás listo para divertirte de una nueva manera en esta nueva aventura?'
    },
    pt: {
      title: 'Chaves Kart',
      description: 'Jump in your favorite kart! Ready for a blast from the past in a whole new way?'
    }
  };

  constructor(
    private router: Router  ,
    public translate: TranslateService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
  }

  ngOnInit(): void {
    this.currentLanguage = 'en';
    this.titleService.setTitle(this.metaTags.en.title);
    this.metaTagService.updateTag(
      { name: 'description', content: this.metaTags.en.description }
    );
  }

  goTo(route) {
    this.router.navigate([route]);
  }

  changeLan(event) {
    console.log(event);
    this.currentLanguage = event;
    this.translate.use(event);
    this.titleService.setTitle(this.metaTags[event].title);
    this.metaTagService.updateTag(
      { name: 'description', content: this.metaTags[event].description }
    );
  }

}
