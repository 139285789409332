import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    private router: Router
  ) {
   
   }

  ngOnInit(): void {
    const element = document.getElementById('title');    
    element.scrollIntoView();
  }

  goBack() {
    this.router.navigate(['/home']);
  }

}
