<app-navbar (selectedLan)=(changeLan($event))></app-navbar>

<div id="header" class="container-fluid px-0 position-relative">
    <div class="header-background-video-container">
        <div id="header-background-video" class="embed-responsive embed-responsive-21by9">
            <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/423307719?background=1" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div id="header-info-section" class="col-12 col-sm-6 pt-5 mt-5 pl-md-2 text-center text-md-left d-flex align-items-center align-items-md-start flex-column">
                <div class="logo-chavo-kart">
                    <img src="../../assets/images/{{ 'LOGO' | translate}}" alt="Logo Chavo Kart" class="img-fluid">
                </div>
                <div class="py-4">
                    <h1>{{ 'HOME.text-1' | translate}}</h1>
                    <p class="font-24">{{ 'HOME.text-2' | translate}}</p>

                </div>
                <div class="header-download-button-container">
                    <div class="header-app-button button-appstore pointer">
                        <a href="{{ 'APPSTORE_URL' | translate}}" target="_blank">
                            <img src="../../assets/images/{{ 'APPSTORE_IMAGE' | translate}}" alt="Button App Store " class="img-fluid ">
                        </a>
                    </div>
                    <div class="header-app-button button-googleplay pointer">
                        <a href="{{ 'GOOGLEPLAY_URL' | translate}}" target="_blank">
                            <img src="../../assets/images/{{ 'GOOGLEPLAY_IMAGE' | translate}}" alt="Button Google Play " class="img-fluid ">
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
<div id="video-section" class="container-fluid px-0 position-relative">
    <div class="row py-5 py-md-0">
        <div class="translateY col-12 pt-5 d-flex align-items-center justify-content-center">
            <div class="video-container mt-5">
                <div id="video-1" class="video embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/423318728?background=1" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="features-section" class="container-fluid px-0 position-relative">
    <div class="row py-5 filter-shadow">
        <div id="green-bar" class="col-12 d-flex align-items-center justify-content-center">
            <div class="title">
                <h1>
                    {{ 'NAVBAR.text-1' | translate}}
                </h1>
            </div>
        </div>
    </div>
</div>

<div id="slider-section" class="px-md-5">

    <app-slider></app-slider>

</div>

<div id="how-to-section">
    <div class="filter-shadow">
        <div class="container-fluid py-5">
            <div class="container">
                <div class="row pt-5 pb-2 mt-3">
                    <div class="col-12 col-md-6 offset-md-3">
                        <div class="title">
                            <h1 class="text-center">
                                {{ 'NAVBAR.text-2' | translate}}
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row py-5 py-md-0">
                    <div class="col-12 pb-5 d-flex align-items-center justify-content-center">
                        <div class="video-container">
                            <div *ngIf="currentLanguage === 'en'" id="video-2" class="video embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/423309340?background=1" allowfullscreen></iframe>
                            </div>
                            <div *ngIf="currentLanguage === 'es'" id="video-2" class="video embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/431970898?background=1" allowfullscreen></iframe>
                            </div>
                            <div *ngIf="currentLanguage === 'pt'" id="video-2" class="video embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/431971203?background=1" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="stores-section">
    <div class="container-fluid">
        <div class="col-12 offset-0 col-md-8 offset-md-2 header-download-button-container">
            <div class="header-app-button button-appstore pointer">
                <a href="{{ 'APPSTORE_URL' | translate}}" target="_blank" rel="noopener noreferrer">
                    <img src="../../assets/images/{{ 'APPSTORE_IMAGE' | translate}}" alt="Button App Store" class="img-fluid">
                </a>
            </div>
            <div class="header-app-button button-googleplay pointer">
                <a href="{{ 'GOOGLEPLAY_URL' | translate}}" target="_blank" rel="noopener noreferrer">
                    <img src="../../assets/images/{{ 'GOOGLEPLAY_IMAGE' | translate}}" alt="Button Google Play" class="img-fluid">
                </a>
            </div>
        </div>
    </div>
</div>

<div id="contact-section">
    <div class="filter-shadow">
        <div class="container-fluid"></div>
    </div>
    <div class="container-fluid form-container pt-5">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 py-5 text-center">
                <div class="title">
                    <h1>{{ 'HOME.text-11' | translate}}</h1>
                </div>
                <p class="paragraph text-center white">
                    {{ 'HOME.text-12' | translate}}
                </p>
                <p class="paragraph text-center white">
                    {{ 'HOME.text-13' | translate}}
                </p>
                <!-- <div class="pt-5" style="width: 100%;">
                    <app-contact></app-contact>
                </div> -->
                <div class="social-buttons-container py-4 d-flex flex-row justify-content-around">
                    <div class="social-button fb">
                        <a href="https://www.facebook.com/elchavokartgame" target="_blank"><img src="/assets/images/button-facebook.png " alt="Social Button FB " class="img-fluid "></a>
                    </div>
                    <div class="social-button yt mx-4">
                        <a href="https://www.youtube.com/user/KokonutStudio" target="_blank"> <img src="/assets/images/button-youtube.png " alt="Social Button YT " class="img-fluid "></a>
                    </div>
                    <div class="social-button ig">
                        <a href="https://www.instagram.com/elchavokartgame/" target="_blank"><img src="/assets/images/button-instagram.png " alt="Social Button IG " class="img-fluid "></a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div id="footer-section">
    <div class="filter-shadow">
        <div class="container-fluid py-5">
            <div class="container pt-5 ">
                <div class="row ">
                    <div class="footer-info col-12 d-flex flex-column flex-md-row align-items-center justify-content-around">
                        <div class="terms-container d-flex flex-column flex-row-md text-center justify-content-between ">
                            <!-- <div class="copyright text-center pb-1">
                                {{ 'FOOTER.text-2' | translate}}
                            </div> -->
                            <div (click)="goTo('/terms')" class="terms pointer py-1 "> {{ 'TERMS.terms' | translate}}</div>
                            <div (click)="goTo('/privacy')" class="privacy pointer"> {{ 'TERMS.privacy' | translate}}</div>
                        </div>
                        <div class="pt-4 pt-md-0 text-center d-flex flex-column justify-content-center align-items-center">
                            <div class="copyright">
                                {{ 'FOOTER.text-1' | translate}}
                            </div>
                            <div class="kgames-logo-container py-2">
                                <a href="https://kokonut.games" target="_blank" rel="noopener noreferrer">
                                    <img src="/assets/images/k-games-logo.png " alt="K-Games Logo " class="img-fluid ">
                                </a>
                            </div>
                            <div class="copyright">
                                {{ 'FOOTER.copyrigtht' | translate}}
                            </div>
                        </div>
                        <div class="header-download-button-container d-flex flex-column align-items-center align-items-md-end">
                            <div class="footer-app-button button-appstore pointer pb-1">
                                <a href="{{ 'APPSTORE_URL' | translate}}" target="_blank">
                                    <img src="../../assets/images/{{ 'APPSTORE_IMAGE' | translate}}" alt="Button App Store " class="img-fluid ">
                                </a>
                            </div>
                            <div class="footer-app-button button-googleplay pointer ">
                                <a href="{{ 'GOOGLEPLAY_URL' | translate}}" target="_blank">
                                    <img src="../../assets/images/{{ 'GOOGLEPLAY_IMAGE' | translate}}" alt="Button Google Play " class="img-fluid ">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>